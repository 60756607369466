.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  z-index: 1030;
  .nav-link {
    color: rgba(255, 255, 255, 0.5);
    transition: background-color 0.3s linear, color 0.3s linear;
  }
  .sidenav-bg:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $border-radius;
    .nav-link {
      color: white;
    }
  }
  .sidenav-bg .active {
    background-color: $primary;
    border-radius: $border-radius;
    color: white;
  }
  .collapse {
    &.show {
      display: block;
      transition: max-height 0.3s linear;
      max-height: 1000px;
    }
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s linear;
  }
  .collapse .nav-link {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .collapse .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $border-radius;
  }
  .collapse .nav-link.active {
    background-color: $primary;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 0;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: margin-left 0.3s linear;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
