.crud-user .crop-container {
    position: relative;
    width: 100%;
    height: 300px;
    background: #333;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crud-user .controls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crud-user input[type="range"] {
    width: 200px;
    margin: 0 10px;
}

.crud-user button {
    margin-top: 20px;
    z-index: 10;
    position: relative;
    /* Botón sobre el cropper */
}