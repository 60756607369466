.tooltip-container {
    max-width: 430px; /* Ancho máximo */
    padding: 10px; /* Espaciado interno */
    background-color: #333; /* Fondo oscuro */
    color: #fff; /* Texto blanco */
    font-size: 14px; /* Tamaño del texto */
    border-radius: 5px; /* Bordes redondeados */
    white-space: pre-wrap; /* Permite saltos de línea */
    word-wrap: break-word; /* Divide palabras largas */
    z-index: 10000; /* Asegura que esté visible por encima de otros elementos */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Agrega sombra */
}
