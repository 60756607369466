.pageWrapper {
  min-height: 100vh;
}

.contentArea {
  flex-grow: 1;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
  background-color: $white;
}

.list-group-item {
  background-color: $white !important;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}

.button-group .btn {
  margin: 3px;
}

table {

  th,
  td {
    background-color: $white !important;
  }

  th {
    font-weight: 500;
  }
}

.dropdown-menu {
  background-color: $white !important;
}

.logoHeader {
  width: 10px;
  height: auto;
  // margin-left: -20px;
}

.margen-derecha {
  margin-right: 65%;
  margin-left: 20%;
}

.search-input-container {
  display: flex;
  justify-content: flex-end;
}

.search-input {
  width: 30%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.buttons-export {
  background-color: #c4c2c2;
  border: none;
}

.pagination {
  display: flex;
  justify-content: right;
}

.data-table {
  width: 100%;
  box-sizing: border-box;
}

.data-table th {
  background-color: #1E2A35 !important;
  color: #c1c7ce !important;
  padding: 5px;
}

.data-table .p-datatable-tbody>tr>td,
.data-table .p-datatable-thead>tr>th {
  border: 1px solid #ccc !important;
}

.data-table .p-datatable-tbody>tr>td {
  padding: 7px;
}

.nowrap-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ESTILOS PARA LOS CAMBIOS DE MONEDA
.iframe-container {
  position: relative;
  width: 100%;
  // padding-bottom: 44%; /* Adjust this to control the aspect ratio (4:3 in this case) */
  height: 405px;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
